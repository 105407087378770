<template>
  <div>
    <b-container class="white-card">
      <h1>Prislista lackering</h1>
      <b-form-group>
        <b-form-radio-group
          v-model="selected"
          :options="options"
          class="desktop-center"
          value-field="item"
          text-field="name"
        ></b-form-radio-group>
      </b-form-group>
      <b-table striped hover :items="computedItems" :fields="fields">
        <template #cell(amount)="data">
          <input type="number" value="0" @change="updateAmount(data.index)" :ref="data.index" v-if="data.value"/>
        </template>
      </b-table>
      <div style="text-align:center;">
        <b-button :to="{name: 'contact'}">Kontakta oss</b-button>
      </div>
    </b-container>
  </div>
</template>

<script>
import { BTable, BFormRadioGroup, BFormGroup, BButton } from 'bootstrap-vue'
export default {
  name: "PriceView",
  components: {
    BTable,
    BFormRadioGroup,
    BFormGroup,
    BButton
  },
  data: function() {
    return {
      selected: "industrial",
      priceIncreaseHistory: [3.4], // Höjt 10%. för att höja yttligare 5 [10, 5]
      options: [
        { item: "industrial", name: "Tidigare industrilackerade" },
        { item: "hand", name: "Tidigare handmålade" },
        { item: "clear", name: "Trä eller fanér/klarlackade" }
      ],
      fields: [
        {
          key: "type",
          label: ""
        },
        {
          key: "amount",
          label: "Antal"
        },
        {
          key: "price",
          label: "Pris"
        },
        {
          key: "priceWithoutTax",
          label: "Pris (exkl. moms)"
        }
      ],
      items: {
        industrial: [
          {
            type: "Garderobs- skafferi och städskåpsluckor (upp till 200cm)",
            price: 554,
            unit: ":-",
            amount: 0
          },
          {
            type: "Övriga luckor (upp till 130cm)",
            price: 347,
            unit: ":-",
            amount: 0
          },
          { type: "Lådfront", price: 185, unit: ":-", amount: 0},
          { type: "Grytlåda", price: 254, unit: ":-", amount: 0},
          {
            type: "Innedörr (samtliga sidor",
            price: 1155,
            unit: ":-",
            amount: 0
          },
          { type: "Dörr B15 tung", price: 1733, unit: ":-", amount: 0},
          { type: "Kryddhylla", price: 693, unit: ":-", amount: 0}
        ],
        hand: [
          {
            type: "Garderobs- skafferi och städskåpsluckor (upp till 200cm)",
            price: 774,
            unit: ":-",
            amount: 0
          },
          {
            type: "Övriga luckor (upp till 130cm)",
            price: 508,
            unit: ":-",
            amount: 0
          },
          { type: "Lådfront", price: 231, unit: ":-", amount: 0},
          { type: "Grytlåda", price: 323, unit: ":-", amount: 0},
          {
            type: "Innedörr (samtliga sidor",
            price: 1444,
            unit: ":-",
            amount: 0
          },
          { type: "Dörr B15 tung", price: 2079, unit: ":-", amount: 0},
          { type: "Kryddhylla", price: 821, unit: ":-", amount: 0}
        ],
        clear: [
          {
            type: "Garderobs- skafferi och städskåpsluckor (upp till 200cm)",
            price: 855,
            unit: ":-",
            amount: 0
          },
          {
            type: "Övriga luckor (upp till 130cm)",
            price: 578,
            unit: ":-",
            amount: 0
          },
          { type: "Lådfront", price: 277, unit: ":-", amount: 0},
          { type: "Grytlåda", price: 359, unit: ":-", amount: 0},
          {
            type: "Innedörr (samtliga sidor",
            price: 1733,
            unit: ":-",
            amount: 0
          },
          { type: "Dörr B15 tung", price: 2541, unit: ":-", amount: 0},
          { type: "Kryddhylla", price: 947, unit: ":-", amount: 0}
        ],
        other: [
          {
            type: "Demontering/återmontering helt kök",
            price: 924,
            unit: ":-",
            amount: 0
          },
          {
            type: "Demontering/återmontering dörr",
            price: 139,
            unit: ":-",
            amount: 0
          },
          {
            type: "Demontering/återmontering tung B15",
            price: 197,
            unit: ":-",
            amount: 0
          },
          {
            type: "Kulörtillägg NCS",
            price: 440,
            unit: ":-",
            amount: 0
          },
          {
            type: "Borttagning tapet/lagning skador/fuktskador",
            price: 936,
            unit: ":-/tim",
            amount: 0
          },
          {
            type: "Bränna/skrapa bort tidigare färglager",
            price: 936,
            unit: ":-/tim",
            amount: 0
          },
          {
            type: "Spackling/pluggning tica-hål",
            price: 105,
            unit: ":-/st",
            amount: 0
          },
          {
            type: "Spackling beslagshål",
            price: 44,
            unit: ":-/st",
            amount: 0
          },
          {
            type: "Borrning av nya hål",
            price: 44,
            unit: ":-/st",
            amount: 0
          },
          {
            type: "Transport Tur & Retur Södertälje",
            price: 936,
            unit: ":-",
            amount: 0
          },
          {
            type: "Transport Tur & Retur Stockholm Söder",
            price: 1386,
            unit: ":-",
            amount: 0
          },
          {
            type: "Transport Tur & Retur Stockholm Norr",
            price: 1733,
            unit: ":-",
            amount: 0
          }
        ]
      }
    };
  },
  computed:{
  computedItems(){
    // Räkna ut den totala multiplikatorn baserat på tidigare höjningar
    const multiplier = this.priceIncreaseHistory.reduce((acc, percent) => acc * (1 + percent / 100), 1);

    const totalPrice = this.items[this.selected].concat(this.items.other).reduce((result, item) => {
      return result += item.amount * item.price * multiplier;
    }, 0);
    
    return this.items[this.selected].concat(this.items.other).map(item => {
      const newPrice = Math.round(item.price * multiplier); // Beräkna nytt pris
      const priceWithoutTax = Math.round(newPrice / 1.25); // Momsberäkning
      
      return {
        type: item.type,
        amount: item.type, 
        price: `${newPrice}${item.unit}`,
        priceWithoutTax: `${priceWithoutTax}${item.unit}` 
      };
    }).concat([{
      type: "Summa",
      amount: undefined,
      price: `${Math.round(totalPrice)}:-`,
      priceWithoutTax: `${Math.round(totalPrice / 1.25)}:-`
    }]);
  }
},
  methods: {
    updateAmount(index){
      if(this.items[this.selected].length > index){
        this.items[this.selected][index].amount = this.$refs[index].value;
      } else{
        this.items.other[index - this.items[this.selected].length].amount = this.$refs[index].value;
      }
    },
    addPriceIncrease(percent) {
    this.priceIncreaseHistory.push(percent);
  }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
